import classNames from "classnames";
import { t } from "i18n-js";
import { compact } from "lodash";
import { retrievePaywallCheckoutHostname } from "@circle-react/helpers/paywallCheckoutHelpers";
import { isActive } from "@circle-react/helpers/paywallHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { Block } from "./Block";
import { BlockGroup } from "./BlockGroup";
import { Price } from "./Price";
import { usePaywallsBlock } from "./hooks/usePaywallsBlock";

const isPaywallEmpty = (paywall: any) =>
  !paywall.id || !paywall?.web_paywall_price?.id;

const isPaywallValidToRender = (paywall: any) =>
  isActive(paywall) && !isPaywallEmpty(paywall);

export const PaywallsBlock = ({ paywalls }: any) => {
  const { paywallWithErrorsByIndex, isEditing } = usePaywallsBlock();
  const hasFavorites = paywalls.some(
    (paywall: any) => paywall.paywall_highlighted,
  );

  const onClickCheckout = (paywall: any) => {
    const keyParam = "price_id";
    const checkoutUrl = new URL(
      `${retrievePaywallCheckoutHostname()}${paywall.checkout_path}`,
    );
    checkoutUrl.searchParams.set(keyParam, paywall.web_paywall_price.id);
    window.open(checkoutUrl, "_blank");
  };

  return (
    <div className={classNames("bg-primary flex w-full flex-col items-center")}>
      <BlockGroup>
        {compact(
          paywalls.map((paywall: any, index: number) => {
            const paywallDescription = isEditing
              ? paywall.description ||
                t("paywalls.lock_screen.preview.empty_block.description")
              : paywall.description;
            const paywallName = isEditing
              ? paywall.display_name ||
                t("paywalls.lock_screen.preview.empty_block.name")
              : paywall.display_name;

            if (!isPaywallValidToRender(paywall) && !isEditing) {
              return null;
            }

            return (
              <Block
                key={paywall.id}
                isHighlighted={paywall.paywall_highlighted}
                isError={!!paywallWithErrorsByIndex[index]}
                isEmpty={!isPaywallValidToRender(paywall)}
                hasFavorites={hasFavorites}
              >
                <div className="border-primary flex flex-col gap-y-2 border-b pb-4">
                  <Typography.LabelLg truncate>
                    {paywallName}
                  </Typography.LabelLg>
                  <Price
                    price={paywall.web_paywall_price}
                    currency={paywall.currency}
                  />
                </div>
                <div className="flex h-full flex-col justify-between gap-y-4">
                  <Typography.BodySm
                    color={classNames({
                      "text-lightest": isEditing && !paywall.description,
                    })}
                  >
                    {paywallDescription}
                  </Typography.BodySm>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => onClickCheckout(paywall)}
                  >
                    {t("join")}
                  </Button>
                </div>
              </Block>
            );
          }),
        )}
      </BlockGroup>
    </div>
  );
};
