import { Children } from "react";
import classNames from "classnames";

export const BlockGroup = ({ children }: any) => {
  const childrenCount = Children.count(children);

  return (
    <div
      className={classNames(
        "grid max-w-[960px] grid-cols-1 gap-y-4 px-6 md:mx-4",
        {
          "md:w-1/2": childrenCount === 1,
          "md:grid md:w-full md:gap-4": childrenCount >= 2,
          "md:w-full md:grid-cols-2": childrenCount === 2,
          "md:w-full md:grid-cols-3": childrenCount > 2,
        },
      )}
    >
      {children}
    </div>
  );
};
