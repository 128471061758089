import { useHistory } from "react-router-dom";
import {
  isCommunityAdmin,
  isMemberDirectoryVisible,
} from "@/react/helpers/communityMemberHelpers";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { communitiesPaths } from "@circle-react/helpers/routerPathHelpers";
import {
  useChatNotification,
  useUnreadChatThreads,
} from "@circle-react/hooks/chatsV2";
import { useShouldShowMessagingRoutes } from "@circle-react/hooks/useIsMessagingEnabled";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useNavigationLinks } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavigationLinks";
import { PlatformMenuView } from "./PlatformMenuView";
import { useNotificationDropdown } from "./useNotificationDropdown";
import { usePlatformMenu } from "./usePlatformMenu";

export const PlatformMenu = () => {
  const history = useHistory();
  const { enabledCustomLinks } = useNavigationLinks();

  const {
    shouldShowOnboarding,
    isOnboardingActive,
    hideOnboardingConfirmationModal,
    currentCommunity,
    currentCommunitySettings,
    currentCommunityMember,
    showSpotlightSearch,
    shouldShowAllEventsPage,
  } = usePlatformMenu();

  const {
    newNotificationCount,
    toggleNotificationDrawer,
    resetNewNotificationsCount,
  } = useNotificationDropdown();

  const { unreadThreadsCount }: any = useUnreadChatThreads();
  const { unreadChatCount } = useChatNotification();
  const { isV3Enabled } = useIsV3();
  const { data: gamificationSettings } = useGamificationSettings();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const shouldShowMessagingRoutes = useShouldShowMessagingRoutes();
  const shouldShowChatThreadsTab =
    currentCommunityMember?.policies?.can_access_threads_tab ||
    unreadThreadsCount > 0;

  return (
    <PlatformMenuView
      allDMsBadgeText={String(unreadChatCount)}
      hasCurrentCommunityMember={!!currentCommunityMember}
      isHomePageEnabled={currentCommunity?.home_page_enabled}
      isMessagingEnabled={shouldShowMessagingRoutes}
      isOnboardingActive={isOnboardingActive}
      notificationBadgeText={String(newNotificationCount)}
      onGettingStartedClick={() => history.push(communitiesPaths.onboarding())}
      onGettingStartedClose={hideOnboardingConfirmationModal.show}
      onNotificationClick={() => {
        toggleNotificationDrawer();
        resetNewNotificationsCount();
      }}
      onSearchClick={showSpotlightSearch}
      shouldShowMemberDirectory={isMemberDirectoryVisible(
        currentCommunityMember,
        currentCommunitySettings,
      )}
      shouldShowOnboarding={shouldShowOnboarding}
      shouldShowSpaceDirectory={
        currentCommunitySettings?.space_directory_enabled
      }
      shouldShowCoursesDirectory={
        currentCommunity?.policies?.can_view_courses_directory
      }
      shouldShowChatThreadsTab={shouldShowChatThreadsTab}
      resetNewNotificationsCount={resetNewNotificationsCount}
      unreadThreadsCount={unreadThreadsCount}
      shouldShowAllEventsPage={shouldShowAllEventsPage}
      shouldShowLeaderboard={
        isV3Enabled &&
        currentCommunitySettings?.gamification_enabled &&
        (gamificationSettings?.enabled || isAdmin)
      }
      customLinks={enabledCustomLinks}
    />
  );
};
