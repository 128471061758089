import classNames from "classnames";
import { noop } from "lodash";
import { Icon } from "@circle-react-shared/Icon";

interface LeftNavigationButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const LeftNavigationButton = ({
  onClick = noop,
  isDisabled = false,
  isLoading = false,
}: LeftNavigationButtonProps) => {
  const iconType = isLoading ? "loader" : "20-chevron-left";

  return (
    <button
      type="button"
      className={classNames(
        "fixed top-1/2 hidden h-24 w-24 -translate-y-1/2 lg:left-10 xl:left-36",
        "isolate z-10 items-center justify-center lg:flex",
      )}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
    >
      <div
        className={classNames(
          "bg-primary hover:bg-tertiary text-default hover:text-dark",
          "relative h-10 w-10",
          "flex items-center justify-center rounded-full",
          { "cursor-not-allowed opacity-50": isDisabled },
        )}
      >
        <Icon size={20} type={iconType} />
      </div>
    </button>
  );
};
