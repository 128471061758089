import { useFormContext } from "react-hook-form";
import { BROADCAST_TYPES } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { useBroadcastCreate } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/useBroadcastCreate";
import { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { useCurrentAction } from "../../../ActionRule";

export const useUpsertBroadcast = (existingBroadcastId: number | null) => {
  const { paramsPath } = useCurrentAction();
  const { getValues } = useFormContext();
  const { data, mutateAsync: createBroadcast } = useBroadcastCreate();
  const broadcastId = Number(existingBroadcastId ?? data?.id);
  const { mutateAsync: updateBroadcast } = useUpdateBroadcast({
    id: broadcastId,
  });

  const handleUpsert = () => {
    const [internalName, subject, previewText] = getValues([
      `${paramsPath}.internal_name`,
      `${paramsPath}.subject`,
      `${paramsPath}.preview_text`,
    ]);
    const payload = {
      internal_name: internalName,
      broadcast_type: BROADCAST_TYPES.workflow,
    } as const;
    if (broadcastId) {
      return updateBroadcast({
        body: {
          ...payload,
          broadcast_content: {
            subject,
            preview_text: previewText,
          },
        },
      });
    }
    return createBroadcast({
      ...payload,
      broadcast_content_attributes: {
        subject,
        preview_text: previewText,
      },
    });
  };

  return {
    onUpsert: handleUpsert,
    broadcastId,
  };
};
